<script>
import AppFooter from '@/components/AppFooter'

export default {
  name: 'celosezonne',
  components: {
    AppFooter
  },
  data () {
    return {
      pricelist: {
        celosezonne: [
          {
            title: "Deti (do 130 cm)",
            rows: [
              [ "komplet štandard", "(lyže, lyžiarky, prilba, palice)", 60 ],
              [ "komplet top model", "(lyže, lyžiarky, prilba, palice)", 80 ],
              [ "komplet snowboard", "(snowboard, topánky)", 80 ],
              [ "lyže štandard", "", 40 ],
              [ "lyžiarky", "", 30 ]
            ]
          },
          {
            title: "Dospelí - juniori",
            rows: [
              [ "komplet štandard", "(lyže, lyžiarky, prilba, palice)", 90 ],
              [ "komplet top model", "(lyže, lyžiarky, prilba, palice)", 110 ],
              [ "komplet VIP model", "(lyže, lyžiarky, prilba, palice)", 150 ],
              [ "komplet bežecký", "(lyže, topánky, palice)", 80 ],
              [ "komplet snowboard", "(snowboard, topánky)", 100, 150 ],
              [ "lyže štandard", "", 70 ],
              [ "lyže top / VIP model", "(okrem lyží určených na predaj)", 90, 130 ],
              [ "lyžiarky", "", 40 ]
            ]
          }
        ]
      }
    }
  },
  methods: {
    scrollTo(ref) {
      const element = this.$refs[ref].$el
      window.scrollTo(0, element.offsetTop - 120);
    },
    openModal(n) {
      this.$refs[`modal${n}`].open()
    }
  }
};
</script>

<template>
  <div class="celosezonne-view">
    <article>
      <div class="content-wrapper">
        <h1>CELOSEZÓNNE POŽIČANIE</h1>
        
        <h2>Výhodné požičanie výstroje na celú sezónu</h2>

        <p><b>Výhodná akcia!</b> Ponúkame Vám detskú lyžiarsku výstroj len za 50 € na celú zimnú sezónu. Využite našu ponuku a doprajte zážitok zo snehu aj najmenším.</p>
      </div>

      <div class="full-box full-box-kurzy bg-detske-vystroje">
        <div class="full-box-content">
          <h3 style="color:#6d93c4">DETSKÉ LYŽIARSKE VÝSTROJE</h3>
          <p><font-awesome-icon icon="check" /> lyže v dĺžkach 70 - 140 cm</p>
          <p><font-awesome-icon icon="check" /> lyžiarska obuv vo veľkostiach 15 - 24</p>
          <p><font-awesome-icon icon="check" /> lyžiarske palice + prilba</p>
          <p><font-awesome-icon icon="check" /> bezplatná veľkostná výmena počas celej sezóny</p>
        </div>
      </div>

      <div class="content-wrapper">
        <h2>Cenník celosezónneho požičania</h2>
        <table class="pricelist">
          <tbody>
          <template v-for="(section, index) in pricelist.celosezonne">
            <tr class="title" :key="`title${index}`">
              <th style="text-align: left;">{{ section.title }}</th>
              <td></td>
            </tr>

            <tr v-for="(row, rowIndex) in section.rows" :key="`row${index}${rowIndex}`">
              <th style="text-align: left">
                {{ row[0] }}
                <span class="note">{{ row[1] }}</span>
              </th>
              <td style="text-align: center">
                {{ row[2] | euro }}
                <template v-if="row[3]">
                   - {{ row[3] | euro }}
                </template>
              </td>
            </tr>
          </template>
        </tbody></table>
      </div>
    </article>

    <app-footer>
      <b>Naša ponuka:</b>
        <ul>
          <li><router-link :to="{ name: 'pozicovna' }">Požičovňa lyžiarskej, snowboardovej, skialpovej výstroje</router-link></li>
          <li><router-link :to="{ name: 'skiservis' }">Servis lyží a snowboardov</router-link></li>
          <li><router-link :to="{ name: 'predaj' }">Predaj lyží, snowboardov, lyžiarok a doplnkov</router-link></li>
          <li><router-link :to="{ name: 'deti' }">Detské výstroje</router-link></li>
          <li><router-link :to="{ name: 'celosezonne' }">Celosezónne požičanie</router-link></li>
          <li><router-link :to="{ name: 'pozicovna-elektrobicyklov' }">Elektrobicykle</router-link> a <router-link :to="{ name: 'pozicovna-bicyklov' }">bicykle</router-link></li>
          <li><a href="https://www.kolobezkyliptov.sk/" target="_blank">Kolobežky</a></li>
        </ul>
    </app-footer>
  </div>
</template>

<style lang="scss" scoped>
  $title-color: #0968aa;

  .bg-detske-vystroje {
    background-image: url(https://skiservis.imgix.net/celosezonne/detske-vystroje.jpg?auto=format&dpr=1);
    background-color: #bbd5f0;

    @media only screen and (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
        background-image: url(https://skiservis.imgix.net/celosezonne/detske-vystroje.jpg?auto=format&dpr=2);
    }

    @media only screen and (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
        background-image: url(https://skiservis.imgix.net/celosezonne/detske-vystroje.jpg?auto=format&dpr=3);
    }
  }

  .content-wrapper {
    width: 90%;
    margin: 1rem auto;

    & > p {
      font-size: 18px;
      margin: 1rem 0;
    }
  }

  h1 {
    display: none;
  }

  h2 {
    color: $title-color;
    font-size: 30px;
    margin: 2.5rem 0 2rem;
    text-transform: uppercase;
  }

  .pricelist {
    margin: 1rem auto;
    padding: 0;
    width: 60%;
    font-size: 1rem;
    font-family: "Montserrat";
    text-transform: uppercase;
    border-collapse: collapse;
    margin-bottom: 2em;

    @media (max-width: 720px) {
      width: 100%;
    }

    tr {
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }

      &.title {
        background: #bbd5f0;
        border-bottom: none;
      }

      td {
        vertical-align: middle;
        padding: .75rem;
      }

      th {
        font-weight: 300;
        vertical-align: middle;
        padding: .35rem .75rem;
      }
    }

    .note {
      font-size: .75rem;
      color: #999;
    }
  }

  .boxes {
    .box {
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        transform: rotateZ(.5deg);
        z-index: -10;
        filter: blur(3px);
        border-radius: 5px;
      }      

      ::v-deep .title {
        height: 40%;
        background: rgba(0, 0, 0, 0.15) !important;
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;

        h3 {
          font-family: "Montserrat";
          font-size: 20px;
          color: #fff;
          margin: 0;
          padding: .5rem 1rem;
          text-transform: uppercase;
        }
      }

      ::v-deep .sweet-content {
        [data-icon] {
          display: none;
        }

        aside {
          float: right;

          img {
            width: 450px;
            margin-left: 20px;
          }
        }

        p {
          color: #444;
          font-size: 1rem;
          padding: 1rem 0;

          &.line {
            border-bottom: 1px solid #eee;
          }          
        }

        .padded {
          padding-left: 2rem;
        }

        h4 {
          font-size: 18px;
          font-weight: bold;
          clear: right;
        }
      }

      ::v-deep .sweet-buttons {
        border: none;
        text-align: center;
        padding-bottom: 2rem;
      }

      ::v-deep .button-action {
        font-weight: normal;
        font-size: 1.1rem;
        border-radius: 2rem;
        padding: 0.5rem 1.5rem;
      }

      p {
        padding: 1rem;
        margin: 0;
        color: #fff;
      }
    }
  }

  .full-box {
    padding: 0 3%;
    margin-top: 3rem;

    p {
      line-height: 2;
    }

    h3 {
      font-weight: 200;
      font-size: 30px;
      margin: 1rem 0;
    }

    a {
      color: #01a0e2;
    }
  }
</style>
